import { getDateObject } from '@/assets/scripts/utils'

export default {
  computed: {
    getDateFormated() {
      var dateStart = getDateObject(this.$store.state.events.filterDate.dateRange.start)
      var dateEnd = getDateObject(this.$store.state.events.filterDate.dateRange.end)
      return `${dateStart.day}.${dateStart.month}.${dateEnd.day ? ' - ' + dateEnd.day + '.' + dateEnd.month + '.' : ''}`
    },
    isCalenderFiltered() {
      return (this.$store.state.events.filterDate.dateRange) && (
        this.$store.state.events.filterDate.dateRange.start !== this.$store.state.events.filterInitialDate.start 
        || 
        this.$store.state.events.filterDate.dateRange.end !== this.$store.state.events.filterInitialDate.end)
    }
  }
}

import Vue from 'vue'
import Vuex from 'vuex'

import events from './events'
import production from './production'
import search from './search'

Vue.use(Vuex)
Vue.config.devtools = true

export default new Vuex.Store({
    modules: {
      events,
      production,
      search
    }
})

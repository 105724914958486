<template>
  <li class="filter-nav__item">
    <button type="button" :title="translate(text)" class="filter-nav__button link"><span :class="'icon-' + icon" /><span class="link__text-line">{{translate(text)}}</span></button>
  </li>
</template>

<script>
import translations from "@/mixins/translation"

export default {
  mixins: [ translations ],
  props: {
    text: {
      type: String
    },
    icon: {
      type: String
    }
  }
}
</script>

<template>
  <li class="filter-option__item">
    <label>
      <input
        type="checkbox"
        v-if="model"
        @change.prevent="onChange"
        :id="text + '-option'"
        :value="text"
        :checked="isChecked"
        class="filter-option__item-checkbox">
      <span class="filter-option__item-text">
        <span v-if="isDigital">
          <i v-if="isChecked" class="icon icon-livestream-white"></i>
          <i v-else class="icon icon-livestream"></i>
        </span>{{text}}
      </span>
  </label>
  </li>
</template>

<script>
export default {
  props: [
    'text',
    'model',
    'eventType'
  ],
  methods: {
    onChange() {
      this.$store.commit(this.eventType + '/onUpdateFilter', { 
        model: this.model,
        value: this.text
      })
    }
  },
  computed: {
    isChecked() {
      return this.model ? this.$store.state[this.eventType].filter[this.model].indexOf(this.text) >= 0 : false
    },
    isDigital() {
      return this.text === 'Digital'
    }
  }
}
</script>

<template>
  <li 
    class="event-installation padding-module"
    :class="{
      'event-installation--open': open
    }"
  >
    <h2 
      class="event-installation__title no-margin" 
      tabindex="0" 
      v-show="showTitle"
      :id="id"
      :aria-controls="id + '-content'"
      :aria-expanded="open"
      :aria-selected="open"
      @click="toggle"
      @keypress.enter="toggle"
    >
      <div 
        class="event-installation__icon"
      />
      {{ installation.length }} {{ translate(installation.length > 1 ? 'durational-events' : 'durational-event') }}
    </h2>
    <transition 
      @enter="openItem"
      @leave="closeItem"
    >
      <ul 
        v-show="open"
        class="event-installation__items grid-x"
        :id="id + '-content'"
        :aria-hidden="!open"
        :aria-labelledby="id"
      >
        <li 
          class="event-item event-installation__item padding-module cell small-12 medium-6" 
          v-for="event in installation" 
          :key="event.id">
          <p 
            class="event-item__genre" 
            v-if="event.genre.length"><span v-for="(item, i) in filterGenre(event.genre)" :key="event.id + 'genre' + item">{{item}}{{ i === (filterGenre(event.genre).length - 1) ? '' : ', ' }}</span></p>
          <component 
            :is="showLink ? 'a' : 'span'" 
            :href="event.get_absolute_url" 
            :class="{'link': showLink}">
            <h3 class="no-margin link__text event-item__title"><span>{{event.name}}</span></h3>
            <h4 class="h3 no-margin link__text light event-item__title"><span>{{event.subtitle}}</span></h4>
          </component>
          <ul class="event-item__infos grid-x align-middle">
            <li class="event-item__info event-item__info--hour cell small-12">{{ event.alternative_time_text_is_not_print_date_time ? event.alternative_time_text_description : getHour(event.date_start) }}</li>
            <li class="event-item__info event-item__info--description cell small-12">{{ event.room_description ? event.room_description + ', ' : '' }}{{ event.venue_description }}</li>
            <li class="event-item__info event-item__info--button cell small-12">
              <event-ticket 
                :ticket_status="event.ticket_status" 
                :waitinglist_link="event.waitinglist_link"
                :ticket_id="event.ticket_id"
                :ticket_weblink="event.ticket_weblink"
                :lowest_price="event.lowest_price"
                :sales_info_ext="event.sales_info_ext" />
            </li>
          </ul>
        </li>
      </ul>
    </transition>
  </li>
</template>

<script>
import translation from "@/mixins/translation"
import eventItem from '@/mixins/event-item'
import EventTicket from '@/components/EventList/EventTicket'
import { getId } from '@/assets/scripts/utils'

export default {
  mixins: [translation, eventItem],

  data() {
    return {
      open: true,
      id: ''
    }
  },

  props: {
    installation: {
      type: Array,
      default: () => []
    },
    showLink: Boolean,
    showTitle: Boolean
  },

  components: {
    EventTicket
  },

  mounted() {
    this.id = getId()
    this.$root.$on('openAll', this.openAll)
    this.$root.$on('closeAll', this.closeAll)
  },

  methods: {
    openAll() {
      this.open = true
    },
    closeAll() {
      this.open = false
    },
    toggle() {
      this.open = !this.open
    },

    openItem(el, done) {
      this.gsap.set(el, { height: 0 })
      this.gsap.to(el, {
        height: el.scrollHeight,
        ease: 'power1.inOut',
        duration: .5,
        onComplete: done
      })

    },
    closeItem(el, done) {
      this.gsap.to(el, {
        height: 0,
        ease: 'power1.inOut',
        duration: .5,
        onComplete: done
      })
    },
    filterGenre(filterItem) {
      return filterItem.filter(item => item !== 'Junges Publikum' && item !== 'Young audience')
    }
  },
}
</script>

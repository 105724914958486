<template>
  <div class="checkbox-toggle">
    <header class="checkbox-toggle__header">
      <label :for="model" class="checkbox-toggle__label" :title="title">
        <input
          :id="model"
          class="checkbox-toggle__input-checkbox"
          type="checkbox"
          name="cookie-permission"
          :checked="checked"
          :disabled="disabled"
          @change="handleCheckbox(pathService, reset)"
        />
        <span class="checkbox-toggle__checkmark" >
          <svg :class="{ 'checkbox-toggle__icon--active': checked }" xmlns="http://www.w3.org/2000/svg" width="12.099" height="9.797" viewBox="0 0 12.099 9.797" class="checkbox-toggle__icon">
    <path id="Path_3386" data-name="Path 3386" d="M40,17.667l2.072,2.707a1.646,1.646,0,0,0,2.609-.032L50,13" transform="translate(-38.949 -11.953)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
    </svg>
        </span>
      </label>
      <aside class="checkbox-toggle__text">
        <h3 class="checkbox-toggle__title" :style="service ? styles.checkboxTextOption : styles.checkboxText" @click.prevent="callback">{{ title }}</h3>
        <a v-if="link" data-no-swup class="checkbox-toggle__subtitle"  rel="noopener noreferrer" :href="link" :style="styles.link" target="_blank">{{ linkText }}</a>
      </aside>
    </header>
    <!-- todo: Feature to show future results -->
    <!-- <aside class="checkbox-toggle__aside"></aside> -->
  </div>
</template>

<script>
export default {
  props: {
    model: String,
    service: String,
    handleCheckbox: Function,
    checked: Boolean,
    title: String,
    link: String,
    linkText: String,
    parent: {
      type: String,
      default: 'services'
    },
    styles: Object,
    callback: {
      type: Function,
      default: () => null
    }
  },

  computed: {
    pathService() {
      return this.service ? `${this.service}.${this.model}` : this.model
    },
    reset() {
      return !this.service
    },
    disabled() {
      return this.model === 'requiredCookies' || this.service === 'requiredCookies'
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox-toggle {
  $checkbox: &;

  &__label {
    cursor: pointer;

    &:hover #{$checkbox}__input-checkbox:not(:checked) ~ #{$checkbox}__checkmark {
      background-color: #f1f1f1;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 12px 0;
  }

  &__text {
    margin-left: 16px;
  }

  &__title {
    font-weight: normal;
    padding: 0;
    margin: 0;
    font-size: 17px;
    cursor: pointer;
  }

  &__icon {
    width: 15px;
    height: 15px;
    opacity: 0;
    transition: .32s ease-in-out;
    &--active {
      opacity: 1;
    }
  }

  /* Hide the browser's default checkbox */
  &__input-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    /* On mouse-over, add a grey background color */
    & ~ #{$checkbox}__checkmark {
      /* background-color: #f1f1f1; */
    }

    /* When the checkbox is checked, add a blue background */
    &:focus ~ #{$checkbox}__checkmark {
      outline: 1px auto #007aff;
      background-color: #f1f1f1;
    }

    &:checked ~ #{$checkbox}__checkmark {
      background-color: #fff;
    }
    &:checked ~ #{$checkbox}__checkmark #{$checkbox}__checkmark-circle {
      transform: translate(0, -50%);
    }
    &:checked:disabled ~ #{$checkbox}__checkmark {
      opacity: 0.3;
    }

    &:checked:disabled ~ #{$checkbox}__title {
      opacity: .5;
    }

  }

  /* Create a custom toogle  */
  &__checkmark {
    height: 24px;
    width: 24px;
    border-radius: 4px;
    background-color: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1.5px solid #000;
    position: relative;
  }

  .form-row--inactive & {
    &__title {
      opacity: .7;
    }
  }

  &--bold {
    line-height: 24px;
    #{$checkbox} {
      &__title {
        font-weight: bold;
        font-size: 15px;
      }
      &__header {
        padding: 4px 0;
      }
      &__subtitle {
        font-size: 16px;
        &:focus {
          outline: 1px auto #007aff;
        }
      }
    }  
  }

}

</style>

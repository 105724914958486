import { getLanguage } from '@/assets/scripts/utils'

export default {
  methods: {
    getHour(date_start) {
      let date = new Date(date_start.toString().replace(/\+.+$/g, ''))
      return `${('0' + date.getHours()).slice(-2)}.${('0' + date.getMinutes()).slice(-2)} ${(getLanguage() == 'en' ? '' : 'Uhr')}`
    },
    getTicketText(lowest_price) {
      if (!lowest_price) {
        return 'Tickets'
      }
      return (getLanguage() == 'en' ? 'Tickets from ' : 'Tickets ab ') + lowest_price + ' €'
    }
  }
}

import { toLowerCase } from "@/assets/scripts/utils.js"

import searchMapper from "@/assets/mappers/search.js"

const state = {
  search: "",
  results: searchMapper,
  autocomplete: [],
  autocompleteOpen: false,
  resultsLimit: 6,
  isResultsLimited: true
}

const mutations = {
  setField (state, {field, value}) {
    switch (field) {
      case 'search':
        state[field] = toLowerCase(value)
        break;
      default:
        state[field] = value
        break;
    }
  }
}

const getters = {
  results: (state) => (type) => {
    return state.results[type].slice(0, state.isResultsLimited ? state.resultsLimit : state.results[type].length)
  }
}

export const actions = {
  async requestSearch({ commit, state }) {
    await fetch(window.DjangoApp.api.search+state.search)
      .then(response => response.json())
      .then(data => {
        commit('setField', {
          field: 'results',
          value: data
        })
      })
  },
  async requestAutocomplete({ commit }, value) {
    await fetch(window.DjangoApp.api.autocomplete+value)
      .then(response => response.json())
      .then(data => {
        commit('setField', {
          field: 'autocomplete',
          value: data
        })
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

<template>
  <div class="filter-option motion-item">
    <h3 class="" v-if="title">{{ translate(title) }}</h3>
    <ul class="filter-option__items">
      <filter-option-item
        :text="translate('filter-all')"
        @click.native="$store.commit(eventType + '/onReset', model)"
        @keyup.enter.native="$store.commit(eventType + '/onReset', model)"
        :class="{'filter-option__item--active': isEmpty}"
        tabindex="0"
        :eventType="eventType"
        />
      <filter-option-item
        v-for="option in options"
        :text="option"
        :key="option"
        :model="model"
        :eventType="eventType"
        />
    </ul>
  </div>
</template>

<script>
import translation from "@/mixins/translation"
import FilterOptionItem from "@/components/FilterNav/FilterOptionItem"

export default {
  mixins: [ translation ],
  props: {
    title: String,
    model: String,
    eventType: {
      type: String,
      default: 'events'
    } 
  },
  components: {
    FilterOptionItem  
  },
  computed: {
    options() {
      return this.$store.state[this.eventType].filterOptions[this.model]
    },
    isEmpty() {
      return !this.$store.state[this.eventType].filter[this.model].length
    }
  }
}
</script>

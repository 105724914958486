
export function convertIntObj(obj) {
  const res = {}
  for (const key in obj) {
    res[key] = {};

    const parsed = parseInt(obj[key], 10);
    res[key] = isNaN(parsed) ? obj[key] : parsed;
  }
  return res;
}

export function convertDateFunctional(value) {
  let date = new Date(value.toString().replace(/\+.+$/g, ''));
  return `${(date.getMonth() + 1)}/${date.getDate()}/${date.getFullYear()}`
}

export const getDateObject = (value) => {
  let date = new Date(value.toString().replace(/\+.+$/g, ''))
  return value ? {
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear()
  } : { day: '', month: '', year: '' }
}


export function isSameDay (date1, date2) {
 let dateOne = new Date(date1)
 let dateTwo = new Date(date2)

  dateOne.setHours(0, 0, 0, 0)
  dateTwo.setHours(0, 0, 0, 0)

 return dateOne.getTime() === dateTwo.getTime()
}

export function isDateBetweenRange (date, range) {
  let dateEvent = new Date(date)
  let dateRange = {
    start: new Date(range.start),
    end: new Date(range.end)
  }

  dateEvent.setHours(0, 0, 0, 0)

  return (!range.end) ? dateEvent.getTime() == dateRange.start.getTime() 
    : 
    dateEvent.getTime() >= dateRange.start.getTime() && dateEvent.getTime() <= dateRange.end.getTime()
}

export const getSectionEventBase = (date, item) => ({
  date: new Date(date.toString().replace(/\+.+$/g, '')),
  events: isInstallation(item) ? [] : [item],
  installation: isInstallation(item) ? [item] : []
})

export const isInstallation = (item) => item.activity_status_code === 'INSTALLATION' || item.activity_status_code === 'INS_ERÖ'


export const cleanUpObject = (object) => JSON.parse(JSON.stringify(object))

export const sanitizeString = value => value.toLowerCase().replace(/ü/g, 'u').replace(/ä/g, 'a').replace(/ö/g, 'o');

export const nextElements = function (elem) {
	var siblings = [];
	elem = elem.nextElementSibling;
	while (elem) {
		siblings.push(elem);
		elem = elem.nextElementSibling;
	}
	return siblings;
}

/*
 * Function used to Filter events based on the current filters 
  * */
export const filterByOptions = (event, filterSelected, filters) => {
  let isMatch = true;
  filterSelected.forEach(filter => {
    switch(typeof filters[filter]) {
      case 'object':
        isMatch = ((Array.isArray(event[filter])) ?
          filters[filter].some(item => event[filter].indexOf(item) >= 0)
          : 
          filters[filter].indexOf(event[filter]) >= 0) && isMatch
        break;
      case 'boolean':
        isMatch = event[filter] && isMatch
        break;
    }
  })

  return isMatch
}

/*
 * Function used to generate the filter options and to removeDuplicate/sort/removeEmpty 
  * */
export const removeDuplicatedAndSort = (array) => [...new Set(array)].sort().filter(Boolean)

export const generateOptions = (filter, events) => events.reduce((acc, item) => { 
  (Array.isArray(item[filter])) ? acc.push(...item[filter]) : acc.push(item[filter])
  return acc
}, [])

// export const generateRangeDate = (events, current = false) => ({
export const generateRangeDate = (events) => ({
  end: convertDateFunctional(events[events.length - 1].date_start),
  start: convertDateFunctional(events[0].date_start),
  // start: current ? convertDateFunctional(new Date()) : convertDateFunctional(events[0].date_start)
})


/*
 * months and weeks with translations
 * get basead on new Date()
  * */
const months = {
  en: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  de: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"]
} 
const weeks = {
  en: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  de: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"]
} 

const weeksMondayFirst = {
  en: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
  de: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"]
} 

const lang = document.documentElement.lang

// # new Date().getMonth()
export const getMonthName = (month=0) => months[lang][month]

export const getMonthNameComplete = () => months[lang]

// # new Date().getDay()
export const getWeekName = (week=0) => weeks[lang][week]

export const getWeekComplete = () => weeksMondayFirst[lang]

export const getLanguage = () => lang

/*
 * Convert to lowercase
 */
export const toLowerCase = value => value.toLowerCase().trim();

/*
 * @function - Generate random ID
  * */
export const getId = () => '_' + Math.random().toString(36).substr(2, 9);


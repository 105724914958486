<template>
  <transition-group 
    tag="ul" 
    class="event-wrapper__events"
    v-on:leave="leave"
    v-on:enter="enter"
  >
    <li class="grid-x grid-margin-x event-wrapper__section" 
             v-for="(section, i) in events"
             :key="'section-'+section.date.getTime()" >

      <aside class="cell small-4 cell large-2 padding-module event-wrapper__date">
        <span class="event-wrapper__date-text">{{ getWeekName(section.date.getDay()) }}</span>
        <span class="h1 no-margin event-wrapper__date-text">{{ ('0' + section.date.getDate()).slice(-2) }}</span>
        <span class="event-wrapper__date-text">
          <span>{{ getMonthName(section.date.getMonth()).substr(0, 3) }}</span>
          <span class="event-wrapper__date--desktop">{{ getMonthName(section.date.getMonth()).substr(3) }}</span>
        </span>
      </aside>
      <transition-group 
        tag="ul" 
        class="cell small-8 large-10 event-list"
        v-on:enter="enter"
        v-on:leave="leave"
        >
        <event-list-item 
          v-for="event in section.events"
          v-bind="event"
          :key="event.id"
          :showLink="showLink"
          :smallTitle="smallTitle"
          />
        <event-installation 
          :key="i + '-installation'" 
          v-if="section.installation.length" 
          :installation="section.installation" 
          :showLink="showLink"
          :showTitle="showLink ? true :  !!section.events.length"
          />
      </transition-group>


    </li>
  </transition-group>
</template>

<script>
import EventListItem from "@/components/EventList/EventListItem"
import EventInstallation from "@/components/EventList/EventInstallation"
import { 
  getMonthName, 
  getWeekName, 
} from "@/assets/scripts/utils.js"

export default {
  components: {
    EventListItem,
    EventInstallation
  },
  props: {
    showLink: {
      type: Boolean,
      default: true
    },
    smallTitle: {
      type: Boolean,
      default: false
    },
    pastEvents: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getMonthName,
    getWeekName,
    leave(el, done) {
      this.gsap.timeline({  onComplete: done })
      .to(el, { opacity: 0, duration: 1, ease: 'power1.inOut' })
      .to(el, { height: 0, duration: .7,  ease: 'power2.inOut'  }, '<.5')
    },
    enter(el, done) {
      this.gsap.timeline({  onComplete: done })
      .set(el, { opacity: 0, height: 0 })
      .to(el, { height: 'auto', duration: .8, ease: 'power2.inOut' })
      .to(el, { opacity: 1, duration: .7,  ease: 'power1.inOut'  }, '<.4')
    },
  },
  computed: {
    events() {
      return this.pastEvents ? this.$store.getters['events/getSectionsPast'] : this.$store.getters['events/getSections']
    }
  }
}
</script>

import { filterBase } from '@/assets/scripts/base-filter'
import { cleanUpObject } from '@/assets/scripts/utils'

/**
 * Functions to manipulate URL
 *
 */


// # set
const addToUrl = query => window.history.replaceState('', '', query); 

const generatePathItem = ({ value, field }) => isEmpty(value) ? `/${field}:${value}` : ''

const isEmpty = value => {
  switch(typeof value) {
    case 'object': 
      return !!value.length
    case 'string':
    case 'boolean':
      return value
  }
}

const slashToDash = date => date.replace(/\//g, '-')
const dashToSlash = date => date.replace(/-/g, '/')

const generatePath = object => {
  let path = getUrlReseted() 

  if (object.filterDate) {
    path += generatePathItem({ field: 'date_from', value: slashToDash(object.filterDate.dateRange.start) }) + 
            generatePathItem({ field: 'date_to', value: slashToDash(object.filterDate.dateRange.end) })
  }

  return Object.keys(object.filter).reduce((acc, field) => 
    acc += generatePathItem({ field, value: object.filter[field] }), path)
}


// # get
const filterReset = {
  filterDate: {
    dateRange: { end: '', start: '' }
  },
  filter: filterBase()
}

const getFilterProperty = value => value.indexOf(':') > -1 ? value.split(/:/g) : false


const getCurrentFilter = () => {
  let pathname = decodeURIComponent(window.location.pathname);
  // let pathname = '/en/calendar/date_from:8-19-2021/date_to:8-27-2021/city:Essen,Duisburg,Bochum/genre:Concert,Theatre,Reading,Music,Music theatre'

  return pathname.split(/\//g).reduce((acc, item) => {
    let filter = getFilterProperty(item)

    if(!filter) return acc
    // 0 field, 1 value
    switch (filter[0]) {
      case 'date_from':
        acc.filterDate.dateRange.start = dashToSlash(filter[1]) 
        break;
      case 'date_to':
        acc.filterDate.dateRange.end = dashToSlash(filter[1]) 
        break;
      default:
        acc.filter[filter[0]] = filter[1] == 'true' ? true : filter[1].split(/,/g)
        break;
    }
    return acc 

  }, filterReset);
}

// # reset and verify
const getUrlReseted = () => {
  let path = window.location.pathname.split(/\//g)
  return `/${path[1]}/${path[2]}`
}

const hasFilter = () => window.location.pathname.split(/\//g).filter(item => item.indexOf(':') > -1)


/*
 * Main functions
  * */
const add = object => addToUrl( generatePath( cleanUpObject(object)))

const get = () => !hasFilter().length ? null : getCurrentFilter()

const reset = () => addToUrl(getUrlReseted())

export default {
  add,
  get,
  reset
}

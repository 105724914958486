<template>
  <section class="event-wrapper">
    <event-list 
      :showLink="showLink == 'true'" 
      :smallTitle="true"
      key="event-list" 
      v-show="events.length"
      :class="{
        'event-wrapper__events--linebottom': eventsPastTotal && !isEventLimits
      }"
    />
    <div class="event-wrapper__past" v-show="!events.length || !isEventLimits">
      <h3 class="no-margin" v-show="eventsPast.length">{{ translate('tickets-title-past') }}</h3>
      <event-list 
        :showLink="showLink == 'true'"
        :smallTitle="true" 
        :pastEvents="true"
        key="event-list-past"
      />
    </div>
    <div class="margin-module text-center">
      <button 
        type="button" 
        v-show="shouldShowMoreButton" 
        @click="$store.commit('events/setField', { field: 'isEventLimits', value: false })" 
        class="button line"
      >
        <span class="link__text-line">{{ translate('tickets-show-more') }}</span>
      </button>
    </div>
  </section>
</template>

<script>
import EventList from '@/components/EventList'
import translation from '@/mixins/translation'
import { mapState, mapGetters } from 'vuex'

export default {
  mixins: [ translation ],
  props: {
    bookings: {
      type: String,
    },
    showLink: {
      type: String,
      default: 'false'
    }
  },
  components: {
    EventList 
  },
  computed: {
    ...mapState({
      isLoaded: state => state.events.isLoaded,
      isEventLimits: state => state.events.isEventLimits,
      isEventLimitsPast: state => state.events.isEventLimitsPast,
      eventLimits: state => state.events.eventLimits,
      eventsTotal: state => state.events.events
    }),
    ...mapGetters({
      events: 'events/getEvents',
      eventsPast: 'events/getEventsPast',
      eventsPastTotal: 'events/getEventsPastTotal'
    }),

    shouldShowMoreButton() {
      return  this.events.length ?
                (this.isEventLimits && this.eventsTotal.length > this.eventLimits) :
                (this.isEventLimits && this.eventsPastTotal > this.isEventLimitsPast)
    }
  },
  mounted() {
    this.$store.dispatch('events/loadBookingFromJson', JSON.parse(this.bookings))
    this.$store.commit('events/setField', { field: 'isEventLimits', value: true })
    this.$store.commit('events/setField', { field: 'isEventLimitsPast', value: true })
    this.$store.commit('events/onReset', 'calendar')
  },
  methods: {
  },
  watch: {
    isLoaded () {
      this.EventsComplete.init()
    }
  }
}
</script>

<template>
  <ec-ticket-button v-if="activity_status_code === 'Digi'" partner-id="17" :external-event-id="ticket_id">{{ getTicketText(lowest_price) }}</ec-ticket-button>

  <a v-else-if="ticket_status === 'sale'" :href="ticket_weblink" target="_blank" rel="noopener" class="button green no-margin nobreak">{{ getTicketText(lowest_price) }}</a>

  <a v-else-if="ticket_status === '' && ticket_weblink" :href="ticket_weblink" target="_blank" rel="noopener" class="button green no-margin nobreak">{{ getTicketText(lowest_price) }}</a>

  <div v-else-if="ticket_status === 'soldout_waiting_list'" class="event-ticket__group">
    <button class="button gray " type="button">{{ translate('tickets-souldout') }}</button>
    <a :href="waitinglist_link" :title="translate('tickets-waitlist')" class="link link--showed"><span class="link__text-line">{{ translate('tickets-waitlist') }}</span></a>
  </div>

  <div v-else-if="ticket_status === 'remaining'" class="event-ticket__group">
    <a :href="ticket_weblink" target="_blank" rel="noopener" class="button green no-margin nobreak">{{ getTicketText(lowest_price) }}</a>
    <span>{{ translate('tickets-remaining') }}</span>
  </div>

  <button v-else-if="ticket_status === 'soldout'" class="button gray no-margin" type="button">{{ translate('tickets-souldout') }}</button>

  <span v-else-if="ticket_status === 'past'" >{{ translate('tickets-past-events') }}</span>

  <button v-else-if="ticket_status === 'cancelled'" class="button gray no-margin" type="button">{{ translate('event-cancelled') }}</button>

  <span v-else-if="ticket_status === 'free'" >{{ translate('event-free-entry') }}</span>

  <span v-else-if="ticket_status === 'ext-info'">{{sales_info_ext}}</span>

  <span class="link" v-else>{{ translate('tickets-not-available') }}</span>
</template>

<script>
import eventItem from '@/mixins/event-item'
import translation from "@/mixins/translation"

export default {
  mixins: [translation, eventItem],
  props: {
    lowest_price: String,
    ticket_status: String,
    waitinglist_link: String,
    ticket_id: String,
    ticket_weblink: String,
    sales_info_ext: String,
    activity_status_code: String
  }
}
</script>

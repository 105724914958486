<template>
  <transition-group 
    tag="ul"
    class="filter-selected"
    :class="{'filter-selected--active': !!getFilterSelected.length || isCalenderFiltered}"
    @enter="enterItem"
    @leave="leaveItem" >

    <li 
      class="filter-selected__item" 
      key="selected-calendar"
      v-show="isCalenderFiltered"
      @click="$store.commit('events/onReset', 'calendar')"
      >
      <span class="filter-selected__text">
        <i class="icon-calendar" />
        {{getDateFormated}}
      </span>
    </li>

    <li 
      class="filter-selected__item" 
      v-for="filterSelected in getFilterSelected"
      :key="filterSelected.model+filterSelected.value"
      @click="$store.commit('events/onUpdateFilter', filterSelected)"
      >
      <span class="filter-selected__text">
        <i v-if="filterSelected.value === 'Digital'" class="icon icon-livestream-white"></i>
        {{ filterSelected.value === true ? translate('filter-'+filterSelected.model) : filterSelected.value }}
      </span>
    </li>

    <li 
      class="filter-selected__item filter-selected__item--reset" 
      key="selected-reset"
      v-show="!!getFilterSelected.length || isCalenderFiltered"
      @click="$store.commit('events/onReset', 'all')"
      >
      <span class="filter-selected__text">
        {{translate('filter-reset-all')}}
      </span>
    </li>
  </transition-group>
</template>

<script>
import filter from '@/mixins/filter'
import translation from '@/mixins/translation'

export default {
  mixins: [filter, translation],
  methods: {
    enterItem(el, done) {
      this.gsap.timeline({ onComplete: done })
        .set(el, { width: 0 })
        .to(el, { width: 'auto', opacity: 1, duration: .38, ease: 'power2.inOut' })
    },
    leaveItem(el, done) {
      this.gsap.timeline({ onComplete: done })
        .to(el, { width: 0, opacity: 0, duration: .38, ease: 'power1.inOut' })
    }
  },
  computed: {
    getFilterSelected() {
      return this.$store.getters['events/getFilterSelected']
    }
  }
}
</script>

<template>
  <li class="cell large-10 others-item">
    <a :href="get_absolute_url" class="grid-x">
      <div class="cell auto link">
        <span class="overline">{{es_overline}}</span>
        <h3 class="link__text"><span>{{es_title}}</span></h3>
        <p v-html="highlight" />
      </div>
      <div class="cell image show-for-large">
        <img :src="es_image_url" alt="">
      </div>
    </a>
  </li>
</template>

<script>
  export default {
    props: ["es_title", "es_overline", "es_image_url", "highlight", "get_absolute_url"]
  }
</script>
<template>
  <form action="" @submit.prevent="onSubmit" class="search-form">
    <label
      class="search-label hide-for-large"
      aria-label="Suche"
      title="Suche"
      :for="id">
      <svg xmlns="http://www.w3.org/2000/svg" width="16.6" height="16.5"><g fill="none" stroke="#292929" class="header-final__search-icon"><path stroke-miterlimit="10" d="M2.255 10.74c2.333 2.333 6.152 2.333 8.485 0s2.334-6.152 0-8.486-6.152-2.333-8.485 0c-2.334 2.334-2.334 6.152 0 8.485 0 0 0 0 0 0z"/><path stroke-linecap="round" stroke-linejoin="round" d="M15.76 14.487l-4.454-4.455-1.273 1.273 4.455 4.455a.896.896 0 001.273 0c.636-.495 0-1.273 0-1.273z"/></g></svg>
    </label>
    <input
      type="text"
      class="search-input"
      :class="{ 'search-autocomplete-active': $store.state.search.autocompleteOpen && autocomplete.length }"
      :id="id"
      @focus="onFocus"
      v-model="search"
      :placeholder="placeholder"
      autocomplete="off"
      >
    <button
      type="button"
      class="search-submit show-for-large"
      @click="onSubmit">
        <svg xmlns="http://www.w3.org/2000/svg" width="16.6" height="16.5"><g fill="none" stroke="#292929" class="header-final__search-icon"><path stroke-miterlimit="10" d="M2.255 10.74c2.333 2.333 6.152 2.333 8.485 0s2.334-6.152 0-8.486-6.152-2.333-8.485 0c-2.334 2.334-2.334 6.152 0 8.485 0 0 0 0 0 0z"/><path stroke-linecap="round" stroke-linejoin="round" d="M15.76 14.487l-4.454-4.455-1.273 1.273 4.455 4.455a.896.896 0 001.273 0c.636-.495 0-1.273 0-1.273z"/></g></svg>
    </button>
    <SearchAutocomplete
      :isExternal="isExternal"
      :page="page"
      :isFullList="isFullList"
      />
  </form>
</template>

<script>
  import { mapMutations, mapActions } from 'vuex'
  import SearchAutocomplete from "@/components/Search/SearchAutocomplete"
  import UpdateUrl from "@/mixins/update-url.js"

  export default {
    mixins: [
      UpdateUrl
    ],
    methods: {
      ...mapMutations({
        setField: 'search/setField'
      }),
      ...mapActions({
        requestSearch: 'search/requestSearch',
        requestAutocomplete: 'search/requestAutocomplete'
      }),
      onFocus() {
        this.setField({ field: 'autocompleteOpen', value: true })
      },
      onSubmit() {
        if (this.isFullList || this.isExternal) {
          window.location = window.DjangoApp.search.overview + '?q=' + this.search
        } else {
          this.updateUrl(this.search)
          this.requestSearch()
          this.setField({ field: 'autocompleteOpen', value: false })
          this.updateOutsideVue(this.search)
        }
      }
    },
    components: {
      SearchAutocomplete
    },
    computed: {
      search: {
        get() {
          return this.$store.state.search.search
        },
        set(value) {
          if (!this.$store.state.search.autocompleteOpen) {
            this.onFocus()
          }
          this.requestAutocomplete(value)
          this.setField({ field: 'search', value })
        }
      }
    },
    props: {
      isExternal: {
        type: Boolean,
        default: false
      },
      isFullList: {
        type: Boolean,
        default: false
      },
      page: {
        type: String,
        default: ""
      },
      placeholder: {
        type: String,
        default: ""
      },
      id: {
        type: String,
        default: ""
      }
    }
  }
</script>

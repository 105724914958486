import { 
  generateOptions, 
  removeDuplicatedAndSort, 
  filterByOptions,
  cleanUpObject
} from "@/assets/scripts/utils"

import { filterBase } from '@/assets/scripts/base-filter'

import ManageUrl from '@/assets/scripts/manage-url'

const state = {
  isLoaded: false,
  events: [],
  filterOptions: {
    genre: [],
  },
  filter: {
    genre: []
  }
}

const mutations = {
  onLoadComplete(state) {
    state.isLoaded = true
  },
	onLoadEvents(state, events) {
		state.events = events
  },
  addFilterOptions(state, { filter, options }) {
    state.filterOptions[filter] = options
  },
  setFilterSelected(state, filter='') {
    state.filterSelected = filter
  },
  onUpdateFilter(state, { value='', model }) {
    switch(typeof state.filter[model]) {
      case 'object': {
        const itemPosition = state.filter[model].indexOf(value);
        (itemPosition == -1)  ? state.filter[model].push(value) : state.filter[model].splice(itemPosition, 1)
        break;
      }
      case 'boolean': {
        state.filter[model] = !state.filter[model]
        break;
      }
    }
    ManageUrl.add({
      filter: state.filter,
    })
  },
  onReset(state, model) {
    switch(model) {
      case 'all':
        state.filter = filterBase()
        state.filterDate.dateRange = cleanUpObject(state.filterInitialDate)
        ManageUrl.reset()
        break;
      case 'filter':
        state.filter = filterBase()
        break;
      default:
        state.filter[model] = []
        ManageUrl.reset()
        break;
    }
  },
  saveDate(state, value) {
    state.filterDate = value; 
  },
  saveInitalDate(state, value) {
    state.filterInitialDate = cleanUpObject(value)
  },
  updateFromUrl(state, value) {
    state.filter = value.filter
  }
}

const getters = {
  // verify itens on filter
  hasFilterSelectedProduction: (state) => {
    let hasFilter = Object.keys(state.filter).filter((item) => {
      switch (typeof state.filter[item]) {
        case 'object':
          return !!state.filter[item].length
        case 'boolean':
          return state.filter[item]
      }
    })
    return hasFilter
  },

  getProduction: (state, getters) => {
    let events = state.events.filter(item => {
      let hasOptionsMatch = filterByOptions(item, getters.hasFilterSelectedProduction, state.filter); 
      return hasOptionsMatch
    })
    return events
  }
}

export const actions = {
	async loadProduction({ commit, dispatch }) {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var year = url.searchParams.get("year") || '';
    await fetch(window.DjangoApp.api.production + year)
      .then(response => response.json())
      .then(data => {
        commit('onLoadEvents', data)
        dispatch('generateFilters')
        commit('onLoadComplete')
        dispatch('filterFromUrl')
      })
  },
  generateFilters({ state, commit }) {
    for ( let filter in state.filterOptions ) {
      let options = removeDuplicatedAndSort(generateOptions(filter, state.events))
      commit('addFilterOptions', {
        filter,
        options
      })
    }
  },
  filterFromUrl({ commit }) {
    let value = ManageUrl.get()
    value && commit('updateFromUrl', value)
  }
}

export default {
  namespaced: true,
	state,
	mutations,
	getters,
	actions,
}

<template>
  <search-form
    id="search-input-head"
    class="header-final__search"
    :placeholder="placeholder"
    :page="page"
    :isFullList="isFullList"
    :isExternal="true"
    />
</template>

<script>
import SearchForm from '@/components/Search/SearchForm'

export default {
  components: {
    SearchForm
  },
  computed: {
  },
  props: {
    query: String,
    placeholder: {
      type: String,
      default: ""
    },
    page: {
      type: String,
      default: ""
    },
    isFullList: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    startQuery() {
      if (this.query) {
        this.$store.commit("search/setField", {field: "search", value: this.query})
        this.request()
      }
    },
    request() {
      this.$store.dispatch("search/requestSearch")
    }
  },
  mounted() {
    this.startQuery()
  }
}
</script>

<template>
  <nav class="search-nav event-nav">
    <ul class="event-nav__items">
      <li
        class="event-nav__item"
        :class="{'event-nav__item--active': item.page==page}"
        v-show="getCount(item.store) || !item.store"
        v-for="item in links"
        :key="item.page">
        <a
          class="h3 event-nav__link"
          :class="{'event-nav__link--active': item.page==page}"
          :href="item.link">{{item.text}} {{ item.store ? '(' : '' }}{{getCount(item.store)}}{{ item.store ? ')' : '' }}</a>
      </li>
      <li class="event-nav__indicator" />
    </ul>
  </nav>
</template>

<script>
  import { mapState } from 'vuex'
  import translation from '@/mixins/translation'

  export default {
    mixins: [ translation ],
    computed: {
      // ...mapGetters({
      //   getResults: "search/results"
      // }),
      ...mapState({
        search: state => state.search.search
      }),
      links() {
        return [
          {
            link: window.DjangoApp.search.overview + this.search,
            text: this.translate('overview'),
            page: "all",
            store: ""
          },
          {
            link: window.DjangoApp.search.productions + this.search,
            text: this.translate("productions"),
            page: "production",
            store: "productions"
          },
          {
            link: window.DjangoApp.search.others + this.search,
            text: this.translate("others"),
            page: "other",
            store: "others"
          },
        ]
      }
    },
    methods: {
      getCount(store) {
        return store ? this.$store.state.search.results[store].length : ""
      },
    },
    props: {
      page: String
    },
    mounted() {
      window.Navigation.init()
    }
  }
</script>
import { 
  isSameDay, 
  getSectionEventBase, 
  isInstallation,
  generateOptions, 
  generateRangeDate, 
  removeDuplicatedAndSort, 
  filterByOptions,
  isDateBetweenRange,
  cleanUpObject
} from "@/assets/scripts/utils"

import { filterBase } from '@/assets/scripts/base-filter'

import ManageUrl from '@/assets/scripts/manage-url'

const state = {
  eventLimits: 8,
  eventLimitsPast: 3,
  isEventLimits: false,
  isEventLimitsPast: false,
  isLoaded: false,
  isFilterActive: false,
  filterSelected: '',
  changeUrl: false,
  hideInstallation: false,
  events: [],
  filterOptions: {
    city: [],
    genre: []
  },
  filterInitialDate: {
    start: '', end: ''
  },
  filterCurrentDate: {
    start: '', end: ''
  },
  filterDate: { 
    dateRange: {
      end: '', start: ''
    }
  },
  filter: filterBase()
}

const mutations = {
  onLoadComplete(state) {
    state.isLoaded = true
  },
	onLoadEvents(state, events) {
		state.events = events
  },
  addFilterOptions(state, { filter, options }) {
    state.filterOptions[filter] = options
  },
  toggleFilter(state) {
    state.isFilterActive = !state.isFilterActive
  },
  setFilterSelected(state, filter='') {
    state.filterSelected = filter
  },
  onUpdateFilter(state, { value='', model }) {
    switch(typeof state.filter[model]) {
      case 'object': {
        const itemPosition = state.filter[model].indexOf(value);
        (itemPosition == -1)  ? state.filter[model].push(value) : state.filter[model].splice(itemPosition, 1)
        break;
      }
      case 'boolean': {
        state.filter[model] = !state.filter[model]
        break;
      }
    }
    if (state.changeUrl) {
      ManageUrl.add({
        filter: state.filter,
        filterDate: state.filterDate
      })
    }
  },
  onReset(state, model) {
    switch(model) {
      case 'all':
        state.filter = filterBase()
        state.filterDate.dateRange = cleanUpObject(state.filterInitialDate)
        if (state.changeUrl) {
          ManageUrl.reset()
        }
        break;
      case 'filter':
        state.filter = filterBase()
        break;
      case 'calendar':
        state.filterDate.dateRange = cleanUpObject(state.filterInitialDate)
        if (state.changeUrl) {
          ManageUrl.add({
            filter: state.filter,
            filterDate: state.filterDate
          })
        }
        break;
      default:
        state.filter[model] = []
        break;
    }
  },
  saveDate(state, value) {
    if (state.filterDate.dateRange.start)

      if (state.changeUrl) {
        ManageUrl.add({
          filter: state.filter,
          filterDate: state.filterDate
        })
      }

    state.filterDate = value; 
  },
  saveInitalDate(state, value) {
    state.filterInitialDate = cleanUpObject(value)
  },
  saveCurrentDate(state, value) {
    state.filterCurrentDate = cleanUpObject(value)
  },
  updateFromUrl(state, value) {
    state.filter = value.filter
    state.filterDate = value.filterDate
  },

  setField (state, {field, value}) {
    state[field] = value
  },
}

const getters = {
  // verify itens on filter
  hasFilterSelected: (state) => {
    let hasFilter = Object.keys(state.filter).filter((item) => {
      switch (typeof state.filter[item]) {
        case 'object':
          return !!state.filter[item].length
        case 'boolean':
          return state.filter[item]
      }
    })
    return hasFilter
  },

  getFilterSelected: (state, getters) => {
    let options = getters.hasFilterSelected.reduce((acc, key) => {
      switch (typeof state.filter[key]) {
        case 'object': {
          const filtersSelected = state.filter[key].map(item => ({
            model: key,
            value: item
          }))
          acc = [...acc, ...filtersSelected]
          break;
        }
        case 'boolean': {
          acc.push({
            model: key,
            value: state.filter[key]
          })
          break;
        }
      }
      return acc
    }, []);
    return options
  },

  getEvents: (state, getters) => {
    let events = state.events.filter(item => {
      const hasOptionsMatch = filterByOptions(item, getters.hasFilterSelected, state.filter); 
      const isDateBetween = !state.filterDate.dateRange ? true : isDateBetweenRange(item.date_start, state.filterDate.dateRange)
      const isLimited = state.isEventLimitsPast ? item.ticket_status !== 'past' : true   
      return hasOptionsMatch && isDateBetween && isLimited
    })
    return events.slice(0, state.isEventLimits ? state.eventLimits : events.length)
  },

	getSections: (state, getters) => {
    let events = getters.getEvents.reduce((acc, item) => {
      if (!!acc.length && isSameDay(item.date_start, acc[acc.length - 1].date)) {
        let position = isInstallation(item) ? 'installation' : 'events'
        acc[acc.length-1][position].push(item)
      } else {
        acc.push(getSectionEventBase(item.date_start, item))
      }
      return acc
    }, [])
		return events
  },

  getEventsPastTotal: (state, getters) => {
    let events = state.events.filter(item => {
      const hasOptionsMatch = filterByOptions(item, getters.hasFilterSelected, state.filter); 
      const isDateBetween = !state.filterDate.dateRange ? true : isDateBetweenRange(item.date_start, state.filterDate.dateRange)
      const isLimited = state.isEventLimitsPast ? item.ticket_status === 'past' : true   
      return hasOptionsMatch && isDateBetween && isLimited
    })
    return events.length
  },

  getEventsPast: (state, getters) => {
    let events = state.events.filter(item => {
      const hasOptionsMatch = filterByOptions(item, getters.hasFilterSelected, state.filter); 
      const isDateBetween = !state.filterDate.dateRange ? true : isDateBetweenRange(item.date_start, state.filterDate.dateRange)
      const isLimited = state.isEventLimitsPast ? item.ticket_status === 'past' : true   
      return hasOptionsMatch && isDateBetween && isLimited
    })
    return events.slice(0, state.isEventLimits ? state.eventLimitsPast : events.length)
  },

	getSectionsPast: (state, getters) => {
    let events = getters.getEventsPast.reduce((acc, item) => {
      if (!!acc.length && isSameDay(item.date_start, acc[acc.length - 1].date)) {
        let position = isInstallation(item) ? 'installation' : 'events'
        acc[acc.length-1][position].push(item)
      } else {
        acc.push(getSectionEventBase(item.date_start, item))
      }
      return acc
    }, [])
		return events
  }
}

export const actions = {
	async loadBooking({ dispatch, commit }) {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var year = url.searchParams.get("year") || '';
    await fetch(window.DjangoApp.api.booking + year)
      .then(response => response.json())
      .then(data => {
        commit('setField', { field: 'changeUrl', value: true })
        dispatch('loadBookingFromJson', data)
      })
  },
	loadBookingFromJson({ commit, dispatch }, data) {
        commit('onLoadEvents', data)
        commit('onLoadComplete')
        dispatch('generateFilters')
        dispatch('generateRangeDate')
        dispatch('filterFromUrl')
  },
  generateFilters({ state, commit }) {
    for ( let filter in state.filterOptions ) {
      let options = removeDuplicatedAndSort(generateOptions(filter, state.events))
      commit('addFilterOptions', {
        filter,
        options
      })
    }
  },
  generateRangeDate({ state, commit }) {
    let dateRange = generateRangeDate(state.events)
    let dateCurrentRange = generateRangeDate(state.events, true)
    commit('saveDate', {
      dateRange: dateCurrentRange
    })
    commit('saveInitalDate', dateRange)
    commit('saveCurrentDate', dateCurrentRange)
  },
  filterFromUrl({ commit }) {
    let value = ManageUrl.get()
    value && commit('updateFromUrl', value)
  }
}

export default {
  namespaced: true,
	state,
	mutations,
	getters,
	actions,
}

<template>
  <div class="form-row" :class="{ model, 'form-row--active': isOpen }">
    <header class="form-row__header" >
      <checkbox-toggle 
        :handleCheckbox="handleCheckbox" 
        :model="model"
        :checked="checked"
        :title="title"
        :styles="styles"
        :callback="toggleOpen"
        />
      <button 
        type="button" 
        @click="toggleOpen" 
        class="form-row__showmore"
        :title="!isOpen ? `Show ${title} options` : `Close ${title} options`"
        :class="{ 'form-row__showmore--active': isOpen }" 
      />
    </header>
    <transition name="form-row__wrapper">
      <aside class="form-row__wrapper" v-show="isOpen">
        <p class="form-row__text" v-html="text"></p>
        <options-list 
          v-if="options.length" 
          :options="options"
          :model="model"
          :service="model"
          :services="services" 
          :handleCheckbox="handleCheckbox" 
          :links="links"
          :styles="styles"
        />
      </aside>
    </transition>
  </div>
</template>

<script>
import OptionsList from '@components/CookieManager/OptionsList.vue'
import CheckboxToggle from '@components/CookieManager/CheckboxToggle.vue'

export default {
  components: { OptionsList, CheckboxToggle },
  // props
  props: ['model', 'title', 'text', 'handleCheckbox', 'options', 'services', 'links', 'styles'],

  // data
  data() {
    return {
      isOpen: false
    }
  },

  // computed
  computed: {
    checked() {
      return Object.values(this.services).every(Boolean)
    }
  },

  // mounted
  mounted() {},

  // methods
  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen
    }
  },
}
</script>

<style lang="scss" scoped>
.form-row {
  $formRow: &;

  position: relative;
  transition: background-color 0.2s ease-in-out;

  &--active {
    border-bottom: 2px solid black;
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__showmore {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: 0;
    appearance: none;
    cursor: pointer;
    position: relative;

    &:before, &:after {
      content: '';
      width: 12px;
      height: 2px;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: black;
      border-radius: 6px;
      transition: transform .28s ease-in-out;
    }
    &:before {
      transform: translate(-90%, 100%) rotate(45deg);
      transform-origin: right center;
    }
    &:after {
      transform: translate(0%, 100%) rotate(-45deg);
      transform-origin: left center;
    }

    &:focus {
      outline: 1px auto #007aff;
    }

    &--active {
      &:before {
        transform: translate(-90%, -4px) rotate(-45deg);
        transform-origin: right center;
      }
      &:after {
        transform: translate(0%, -4px) rotate(45deg);
        transform-origin: left center;
      }
    }
  }

  &__text {
    font-size: 15px;
    margin: 8px 0 16px;
    line-height: 24px;
    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
  }

  &__wrapper {
    /* max-height: 0px; */
    overflow: hidden;
    padding-left: 40px;
    &-enter {
      &-active {
        transition: max-height .32s ease-in-out;
        max-height: 0; 
      }
      &-to {
        max-height: 1000px; 
      }
    }
    &-leave {
      &-active {
        transition: max-height .32s ease-in-out;
        max-height: 1000px; 
      }
      &-to {
        max-height: 0px; 
      }
    }
    // &--active {
    //   max-height: 1000px;
    // }
  }

}
</style>

import Vue from 'vue'

// main
import App from './app.vue'

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production'

// If running inside Cypress...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Ensure tests fail when Vue emits an error.
  Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
}

const elm = document.querySelector('#cookie-manager')
const propsData = convertIntObj(elm.dataset)

const app = new Vue({
  render: (h) => h(App, { props: { ...propsData } }),
}).$mount(elm)

// If running e2e tests...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`.
  window.__app__ = app
}
window.__app__ = app


function convertIntObj(obj) {
  const res = {}
  for (const key in obj) {
    res[key] = {};

    const parsed = parseInt(obj[key], 10);
    res[key] = isNaN(parsed) ? obj[key] : parsed;
  }
  return res;
}

export default {
  methods: {
    leave(el, done) {
      this.gsap.timeline({  onComplete: done })
      .to(el, { opacity: 0, duration: 1, ease: 'power1.inOut' })
      .to(el, { height: 0, width: 0, duration: .7,  ease: 'power2.inOut'  }, '<.5')
    },
    enter(el, done) {
      window.Cursor.addListenerElm(el.children[0].children[0])
      this.gsap.timeline({  onComplete: done })
      .set(el, { opacity: 0, height: 0, width: 0 })
      .to(el, { height: 'auto', width: el.offsetWidth, duration: .8, ease: 'power2.inOut' })
      .to(el, { opacity: 1, duration: .7,  ease: 'power1.inOut'  }, '<.4')
    },
    leaveOthers(el, done) {
      this.gsap.timeline({  onComplete: done })
      .fromTo(el, {opacity: 1}, {opacity: 0, duration: .6, ease: 'power2.inOut'})
      // .to(el, { opacity: 0, duration: 1, ease: 'power1.inOut' })
      // .to(el, { height: 0, duration: .7,  ease: 'power2.inOut'  }, '<.5')
    },
    enterOthers(el, done) {
      this.gsap.timeline({  onComplete: done })
      // .set(el, { opacity: 0, height: 0, width: 0 })
      .fromTo(el, {opacity: 0}, {opacity: 1, duration: .6, ease: 'power2.inOut'})
      // .to(el, { height: el.offsetHeight, duration: .8, ease: 'power2.inOut' })
      // .to(el, { opacity: 1, duration: .7,  ease: 'power1.inOut'  }, '<.4')
    },
  },
}
<template>
  <li class="event-item padding-module">
    <span v-if="is_premiere" class="event-item__flag">Premiere</span>
    <p class="event-item__genre" v-if="genre.length"><span v-for="(item, i) in filterGenre" :key="id + 'genre' + item">{{item}}{{ i === (filterGenre.length - 1) ? '' : ', ' }}</span></p>
    <component 
      :is="showLink ? 'a' : 'span'" 
      :href="get_absolute_url" 
      :class="{'link': showLink}">
      <h2 class="no-margin link__text event-item__title" :class="smallTitle ? 'h3' : 'h2' "><span>{{name}}</span></h2>
      <h3 class=" no-margin link__text light event-item__title" :class="smallTitle ? 'h3' : 'h2' "><span>{{subtitle}}</span></h3>
    </component>
    <ul class="event-item__infos grid-x align-middle">
      <li class="event-item__info event-item__info--hour cell small-12 medium-3 large-2">{{ alternative_time_text_is_not_print_date_time ? alternative_time_text_description : getHour(date_start) }}</li>
      <li class="event-item__info event-item__info--description cell small-12 medium-9 large-6">
        <span v-if="isDigital" class="flex-container align-middle digitales-angebot"><i class="icon icon-livestream"></i> <em>{{ translate('digital-offer') }}</em></span>
        <span v-else>{{ room_display_text ? room_display_text + ', '  : '' }}{{ venue_description }}</span>
      </li>
      <li class="event-item__info event-item__info--button cell small-12 large-4">
        <event-ticket
          :ticket_status="ticket_status"
          :waitinglist_link="waitinglist_link"
          :ticket_id="ticket_id"
          :ticket_weblink="ticket_weblink"
          :lowest_price="lowest_price"
          :sales_info_ext="sales_info_ext"
          :activity_status_code="activity_status_code" />
      </li>
    </ul>
  </li>
</template>

<script>
import translation from '@/mixins/translation'
import eventItem from '@/mixins/event-item'
import digitalOffer from '@/mixins/digital-offer'
import EventTicket from '@/components/EventList/EventTicket'

export default {
  mixins: [ translation, eventItem, digitalOffer ],
  props: {
    name: String,
    subtitle: String,
    date_start: String,
    date_end: String,
    room_display_text: String,
    venue_description: String,
    date_publish: String,
    activity_status_code: String,
    activity_status_description_de: String,
    activity_status_description_en: String,
    activity_status_type: String,
    ticket_id: String,
    ticket_weblink: String,
    get_absolute_url: String,
    genre: Array,
    tickets_available: Boolean,
    city: String,
    id: Number,
    is_junge_triennale: Boolean,
    is_premiere: Boolean,
    lowest_price: String,
    ticket_status: String,
    waitinglist_link: String,
    showLink: Boolean,
    smallTitle: Boolean,
    sales_info_ext: String,
    alternative_time_text_description: String,
    alternative_time_text_is_not_print_date_time: Boolean
  },
  components: {
    EventTicket
  },
  computed: {
    filterGenre() {
      return this.genre.filter(item => item !== 'Junges Publikum' && item !== 'Young audience' )
    }
  }
}
</script>

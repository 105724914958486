<template>
  <section class="event-wrapper">
    <filter-option
       model="genre"
       eventType="production"
       class="filter-option--single"
      />
    <div class="spacer small" />
    <production-list v-if="events.length" :events="events" />
  </section>
</template>

<script>
import ProductionList from '@/components/ProductionList'
import FilterOption from "@/components/FilterNav/FilterOption"

import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    ProductionList,
    FilterOption
  },
  computed: {
    ...mapState({
      isLoaded: state => state.production.isLoaded
    }),
    ...mapGetters({
      events: 'production/getProduction'
    }) 
  },
  mounted() {
    this.$store.dispatch('production/loadProduction')
  },
  methods: {
  },
  watch: {
    isLoaded () {
      this.EventsComplete.init()
    }
  }
}
</script>

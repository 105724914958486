export default {
    methods: {
        getTranslations () {
            return window.translations
        },
        translate (text, replacements) {
            const translations = this.getTranslations()
            if (Object.prototype.hasOwnProperty.call(translations, text)) {
                let translated = translations[text]
                if (replacements instanceof Object) {
                    for (let [key, value] of Object.entries(replacements)) {
                        translated = translated.replace('{' + key + '}', value)
                    }
                }
                return translated
            }
            return text
        },
    },
}

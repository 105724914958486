<template>
  <section class="event-wrapper">
    <div class="spacer small" />
    <filter-nav />
    <filter-selected />
    <event-list />
  </section>
</template>

<script>
import EventList from '@/components/EventList'
import FilterSelected from '@/components/FilterSelected'
import FilterNav from '@/components/FilterNav'

import { mapState } from 'vuex'


export default {
  components: {
    EventList, 
    FilterNav,
    FilterSelected
  },
  computed: {
    ...mapState({
      isLoaded: state => state.events.isLoaded
    })
  },
  mounted() {
    this.$store.dispatch('events/loadBooking')
  },
  methods: {
  },
  watch: {
    isLoaded () {
      this.EventsComplete.init()
    }
  }
}
</script>

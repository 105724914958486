<template>
  <ul class="options-list">
    <li v-for="({ id, title, linkText, link }) in options" :key="id"  class="options-list__item">
      <checkbox-toggle 
        :handleCheckbox="handleCheckbox" 
        :model="id"
        :service="service"
        :checked="services[id]"
        :title="title"
        :linkText="linkText || links.copyright.text"
        :link="link || links.copyright.link"
        parent="checkboxes"
        :styles="styles"
        class="checkbox-toggle--bold"
      />
    </li>
  </ul>
</template>

<script>
import CheckboxToggle from '@components/CookieManager/CheckboxToggle.vue'

export default {
  props: {
    options: {
      type: Array,
      default: () => ([])
    },
    service: String,
    services: {
      type: Object,
      default: () => ({})
    },
    links: Object,
    handleCheckbox: Function,
    styles: Object
  },

  components: { CheckboxToggle }
}
</script>

<style lang="scss" scoped>
.options-list {
  list-style: none;
  padding: 0 0 10px;

  @media screen and (min-width: 768px) {
    padding: 0 0 14px;
  }


}
</style>

<template>
  <aside class="filter-nav">
    <ul class="filter-nav__list">
      <filter-nav-button
          text="filter-calendar"
          icon="calendar"
          @click.native="toggleFilter('filter-calendar')"
        />
      <filter-nav-button
          text="filter-list"
          icon="filter"
          @click.native="toggleFilter('filter-list')"
        />
    </ul>
    <transition name="filter-modal" :duration="1000">
      <filter-modal v-if="isFilterActive" />
    </transition>
  </aside>
</template>

<script>
import FilterNavButton from "@/components/FilterNav/FilterNavButton"
import { mapState } from 'vuex'

export default {
  components: {
    FilterNavButton,
    // FilterModal
    FilterModal: () => import(/* webpackChunkName: "filter-modal" */ '@/components/FilterNav/FilterModal')
    
  },
  computed: {
    ...mapState({
      isFilterActive: state => state.events.isFilterActive
    })
  },
  methods: {
    toggleFilter(filter) {
      this.$store.commit('events/toggleFilter')
      this.$store.commit('events/setFilterSelected', filter)
    }
  },
}
</script>

<template>
   <transition name="slide-fade">
    <div class="modal-mask" :style="styleMask">
      <div class="modal-wrapper" :style="styleModal">
        <div class="modal-container">

          <div class="modal-header">
            <slot name="header">
              default header
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              default body
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              default footer
            </slot>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',

  // props
  props: {
    styles: Object,
    showModal: Boolean
  },

  // data
  data() {
    return {
      height: window.innerHeight
    }
  },

  methods: {
    setHeight() {
      this.height = window.innerHeight
    }
  },

  mounted() {
    this.setHeight()
    window.addEventListener('resize', this.setHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setHeight)
  },

  // computed
  computed: {
    styleModal() {
      return {
        ...this.styles.modal,
      }
    },
    styleMask() {
      return {
        height: this.height + 'px'
      }
    }
  },

  watch: {
    showModal(value) {
      if (value) {
        this.setHeight()
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@import 'index.scss';
</style>

<template>
  <transition-group
    tag="ul"
    class="production-list grid-x grid-margin-x"
    v-on:enter="enter"
    v-on:leave="leave"
    >
    <production-list-item
      v-for="event in events"
      v-bind="event"
      :key="event.id"
      />
  </transition-group>
</template>

<script>
import ProductionListItem from "@/components/ProductionList/ProductionListItem"

import TransitionMixin from "@/mixins/transitions-productions.js"

export default {
  components: {
    ProductionListItem,
  },
  mixins: [
    TransitionMixin
  ],
  props: {
    events: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    window.Cursor.addListenerSelector('.news__item-figure')
  }
}
</script>

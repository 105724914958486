<template>
  <ul class="search-autocomplete" :class="{ 'search-autocomplete-active': autocompleteOpen && autocomplete.length }">
    <li
      v-for="(item, i) in charactersBold"
      :key="item"
      @click="onClick(autocomplete[i], $event)"
      @keypress.enter="onClick(autocomplete[i])"
      tabindex="0"
      v-html="item" />
  </ul>
</template>

<script>
  import UpdateUrl from "@/mixins/update-url.js"

  export default {
    mixins: [
      UpdateUrl
    ],
    computed: {
      autocomplete() {
        return this.$store.state.search.autocomplete
      },
      autocompleteOpen() {
        return this.$store.state.search.autocompleteOpen
      },
      charactersBold() {
        return this.autocomplete.map(item => item.replace(this.search, `<strong>${this.search}</strong>`))
      },
      search() {
        return this.$store.state.search.search
      }
    },
    methods: {
      onClick(value, event) {
        if (this.isFullList || this.isExternal) {
          window.location = window.DjangoApp.search.overview + value
        } else {
          this.updateUrl(value)
          this.updateOutsideVue(value)
          this.$store.commit("search/setField",{field: "search", value: value})
          this.$store.dispatch("search/requestSearch")
          this.$store.commit("search/setField", { field: 'autocompleteOpen', value: false })
          event.currentTarget.blur()
        }
      }
    },
    props: {
      isExternal: Boolean,
      isFullList: Boolean,
      page: String
    }
  }
</script>
<template>
  <section>
    <div class="grid-x align-center">
      <div class="cell medium-10 large-8">
        <search-form
          id="search-input"
          :page="page"
          :isFullList="isFullList"
          />
      </div>
    </div>
    <search-nav :page="page" />
    <search-results
      v-for="section in sectionsFiltered"
      v-bind="section"
      :key="section.store"
      :isFullList="isFullList" />
    <div class="spacer"></div>
  </section>
</template>

<script>
import translation from '@/mixins/translation'

import SearchForm from '@/components/Search/SearchForm'
import SearchNav from '@/components/Search/SearchNav'
import SearchResults from '@/components/Search/SearchResults'

export default {
  mixins: [ translation ],
  data() {
    return {
      sections: [
        {
          title: this.translate("productions"),
          store: "productions",
          urlVerify: "production"
        },
        {
          title: this.translate("others"),
          store: "others",
          urlVerify: "other"
        }
      ]
    }
  },
  components: {
    SearchForm,
    SearchNav,
    SearchResults
  },
  computed: {
    sectionsFiltered() {
      return this.isFullList
        ? this.sections.filter(section => section.urlVerify.indexOf(this.page) > -1)
        : this.sections
    },
    isFullList() {
      return this.page !== "all"
    }
  },
  props: {
    query: String,
    page: String
  },
  methods: {
    startQuery() {
      this.$store.commit("search/setField", {field: "search", value: this.query})
      if (this.query) {
        this.request()
      }
    },
    request() {
      this.$store.dispatch("search/requestSearch")
    },
    verifyList() {
      if (this.isFullList) {
        this.$store.commit("search/setField", {field: "isResultsLimited", value: false})
      }
    },
  },
  mounted() {
    this.startQuery()
    this.verifyList()
  }
}
</script>

import Vue from 'vue'
import store from './store'

import { convertIntObj } from '@/assets/scripts/utils.js'

import MainBooking from '@/components/MainBooking.vue'
import MainProduction from '@/components/MainProduction.vue'
import MainTickets from '@/components/MainTickets.vue'
import MainSearch from '@/components/MainSearch.vue'
import HeaderSearch from '@/components/HeaderSearch.vue'

// Vue.config.devtools = false
Vue.config.productionTip = false

Vue.prototype.EventsComplete = window.EventsComplete

var EventsLoad = () => {
  Vue.prototype.gsap = window.gsap

  for (let i of [
      ['#event-content', MainBooking],
      ['#productions-content', MainProduction],
      ['#production-tickets', MainTickets],
      ['#search-content', MainSearch],
      ['#header-search, #overlay-search', HeaderSearch],
  ]) {
      for (let el of document.querySelectorAll(i[0])) {
          const propsData = el.tagName == 'SCRIPT' ? JSON.parse(el.textContent) : convertIntObj(el.dataset)
          new Vue({
              store,
              render: h => h(i[1], { props: { ...propsData } })
          }).$mount(el);
      }
  }
}


window.TimerFunction(EventsLoad, () => ('gsap' in window))

// EventsLoad()

export default {
  methods: {
    updateUrl(value) {
      const newUrl = window.location.pathname + "?q=" + value
      window.history.pushState({}, null, newUrl)
    },
    // updateUrl(value) {
    //   const newUrl = this.removeLastDirectoryPartOf(window.location.pathname)+ "/" + value
    //   window.history.pushState({}, null, newUrl)
    // },
    removeLastDirectoryPartOf(the_url) {
      var the_arr = the_url.split('/');
      the_arr.pop();
      return( the_arr.join('/') );
    },
    updateOutsideVue(value) {
      document.getElementById("query").innerHTML=value
    }
  }
}

<template>
  <li class="bibliothek__item cell small-12 medium-6 large-4">
    <a :href="get_absolute_url" :title="name" class="link">
      <figure class="news__item-figure link__figure" data-cursor="plus">
        <img :src="image_url" :alt="image_text" class="link__image">
      </figure>
      <span> <span v-for="(item, i) in filterGenre" :key="item+'-genre'">{{item}}{{ i == filterGenre.length - 1 ? '' : ', ' }}</span></span>
      <h2 class="link__text no-margin"><span>{{name}}</span></h2>
      <h3 class="link__text h2 no-margin light"><span>{{subtitle}}</span></h3>
      <span v-if="isDigital" class="flex-container align-middle digitales-angebot"><i class="icon icon-livestream"></i> <em>{{ translate('digital-offer') }}</em></span>
    </a>
  </li>
</template>

<script>
import translation from '@/mixins/translation'
import digitalOffer from '@/mixins/digital-offer'

export default {
  mixins: [ translation, digitalOffer ],
  props: {
    name: String,
    subtitle: String,
    production_type_description_de: String,
    get_absolute_url: String,
    image_url: String,
    image_text: String,
    genre: Array
  },
  computed: {
    filterGenre() {
      return this.genre.filter(item => item !== 'Junges Publikum' && item !== 'Young audience' )
    }
  }
}
</script>

<template>
  <section v-if="results.length" class="results-section">
    <h2>{{title}}</h2>
    <transition-group
      tag="ul"
      class="grid-x grid-margin-x"
      :class="{'production-list': store == 'productions', 'others-list align-center': store == 'others'}"
      >
      <component
        :is="componentItem"
        :key="event.id || event.es_title"
        v-for="event in results"
        v-bind="event"
      />
    </transition-group>
    <div v-if="!isFullList" class="text-center">
      <div class="spacer small"></div>
      <a v-if="resultsComplete.length > resultsLimit" :href="resultsLink" class="link">
        <span class="link__text-line">{{translate("load-all-results")}}</span>
        <span class="icon-arrow-right-small"></span>
      </a>
    </div>
  </section>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'

  import translation from '@/mixins/translation'
  import TransitionMixin from "@/mixins/transitions-productions.js"

  import ProductionListItem from "@/components/ProductionList/ProductionListItem"
  import OtherResultsItem from "@/components/Search/OtherResultsItem"

  export default {
    mixins: [ translation, TransitionMixin ],
    components: {
      ProductionListItem,
      OtherResultsItem
    },
    computed: {
      ...mapGetters({
        getResults: "search/results"
      }),
      ...mapState({
        search: state => state.search.search,
        resultsComplete(state) {
          return state.search.results[this.store]
        }
      }),
      results() {
        // return this.$store.getters.search.results("productions")
        return this.getResults(this.store)
      },
      resultsLink() {
        return window.DjangoApp.search[this.store] + this.$store.state.search.search
      },
      resultsLimit() {
        return this.$store.state.search.resultsLimit
      },
      componentItem() {
        switch(this.store) {
          case "productions":
            return "ProductionListItem"
          default:
            return "OtherResultsItem"
        }
      }
    },
    props: {
      title: String,
      store: String,
      isFullList: Boolean
    }
  }
</script>
